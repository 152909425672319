<template>
    <v-row
        justify="center"
    >  
        <v-col>        
            <v-card
                class="mx-auto pa-0"
                width="950"
                height="80vh"
            >
                <v-card-title
                    :class="titleClass"
                >
                    <v-spacer></v-spacer>
                    <div class="caption font-weight-light text-uppercase">Mark Sheet</div>
                    <v-spacer></v-spacer>
                    <v-btn
                        text                
                        x-small
                        color="white"
                        @click="closeMarkSheet"
                    >
                        <v-icon>mdi-window-close</v-icon>
                    </v-btn>            
                </v-card-title>
                <v-row class="px-4">
                    <v-col class="py-0">
                        <Header/>
                        <v-divider></v-divider>
                        <MarkSheet/>
                        <v-row class="mt-3 mr-4">
                            <v-spacer></v-spacer>
                            <div class="caption">
                                Total Students: {{ totalStudents }}
                            </div>
                        </v-row>
                    </v-col>
                </v-row>
                
                <v-overlay
                    :absolute="absolute"
                    :value="overlay"                    
                    color="grey lighten-5"
                    opacity="0.9"
                    class="pa-5"
                >                    
                    <Lesson parent-component="enter-marks" />
                    <lesson-admin></lesson-admin>

                    <div 
                        class="d-flex flex-column justify-center align-center"
                        v-show="loading"
                    >    
                        <v-progress-circular 
                            transition="fade-transition" 
                            indeterminate 
                            size="64"                            
                            color="primary"
                            v-show="loading"
                        ></v-progress-circular>
                        <div 
                            class="primary--text mt-4"
                            v-show="loading"
                        >{{ progressText }}</div>
                    </div> 
                </v-overlay>
               
            </v-card>
        </v-col>
       
    </v-row>    
</template>

<script>
import Header from './EnterMarksHeader'
import Lesson from './EnterMarksLesson'
import MarkSheet from './EnterMarksSheet'
import LessonAdmin from './EnterMarksLessonAdmin'
import { mapGetters, mapMutations } from 'vuex'
export default {
    name: 'EnterMarks',

    components: {
        Header,
        Lesson,
        LessonAdmin,
        MarkSheet,
    },

    created: function () {
        this.initialize();
    },

    computed: {
        ...mapGetters({
            overlay: 'termReports/getMarkSheetOverlay',
            primaryColor: 'termReports/getPrimaryColor',
            colorDarken: 'termReports/getColorDarken',
            mainMenuButton: 'termReports/getMainMenuButton',
            loading: 'termReports/getMarkSheetLoading',
            totalStudents: 'termReports/getSubjectStudentsRegistered',
            progressText: 'termReports/getProgressText',   
        }),
        themeColor(){          
            return this.primaryColor + ' ' + this.colorDarken;
        },
        titleClass(){
            return 'd-flex white--text  py-2 px-0 ' + this.themeColor
        },
        buttonColor(){
            return this.primaryColor + ' ' + this.colorDarken;
        }
    },

    data: () => ({
        absolute: true,             
        userTimetable: [],
    }),

    methods: {
        ...mapMutations({
            enterMarks: 'termReports/setEnterMarks',
            setOverlay: 'termReports/setMarkSheetOverlay',
            expand: 'termReports/setExpandLessonOptions',
            welcomeMessage: 'termReports/setWelcomeMessage',
            table2Records: 'termReports/setTable2Records',
            setStudentName: 'termReports/setMarkSheetStudentSelected',
            setMarkSheetOverlay: 'termReports/setMarkSheetOverlay',
            setMarkSheetLoading: 'termReports/setMarkSheetLoading',
        }),

        setLessonSelected(e){
            this.loading = true;
            console.log(e);
        },

        initialize () {
            this.expand(false);
            this.$nextTick(() => {
                this.setMarkSheetOverlay(true);
                this.setMarkSheetLoading(true);
            })
            
        },

        
        closeMarkSheet(){
            this.setOverlay(true);
            this.table2Records([]);
            this.setStudentName('');
            //this.expand(true);
            
        }
    }
}
</script>