<template>
    <v-list
        dense
        nav
    >
        <v-list-item-group
            v-model="selectedItem"
            color="white"
        > 
            <v-list-item
                v-for="(item, i) in items"
                :key="i"                                      
                @click="display(item)"
                :disabled="item.disabled"
                dark                                    
            >
                <v-list-item-icon v-if="item.visible">
                    <v-icon v-text="item.icon"></v-icon>
                </v-list-item-icon>

                <v-list-item-content v-if="item.visible">
                    <v-list-item-title 
                        v-text="item.text"                        
                    >
                    </v-list-item-title>
                </v-list-item-content>

            </v-list-item>

        </v-list-item-group>                
    </v-list>                 
</template>

<script>
import { mapActions, mapMutations, mapGetters } from 'vuex';
export default {
    name: 'AdminNavigation',

    created: function () {
        this.initialize();
    },

    computed: {
        ...mapGetters({
            menuOptions: 'admin/getMenuOptions',
        })
    },
    
    data: () => ({
        items: [
            { 
                text: 'Dashboard', 
                icon: 'mdi-view-dashboard', 
                submenu: false, 
                menu: 'dashboard', 
                visible: true, 
                disabled: false 
            },                     
            { 
                text: 'Teachers', 
                icon: 'mdi-account-group', 
                submenu: false, 
                menu: 'employees', 
                visible: true, 
                disabled: false 
            },            
            { 
                text: 'Students', 
                icon: 'mdi-account-group', 
                submenu: false, 
                menu: 'students', 
                visible: true, 
                disabled: false 
            },            
            { 
                text: 'Subjects', 
                icon: 'mdi-library', 
                submenu: false, 
                menu: 'studentSubjects', 
                visible: true, 
                disabled: false 
            },            
            { 
                text: 'Post Term Reports', 
                icon: 'mdi-post', 
                submenu: false, 
                menu: 'postReports', 
                visible: true, 
                disabled: false 
            },            
            { 
                text: 'Edit\\View Term Details', 
                icon: 'mdi-file-document-edit-outline', 
                submenu: false, 
                menu: 'termReports', 
                visible: true,
                disabled: false  
            },
            { 
                text: 'Enter Marks', 
                icon: 'mdi-keyboard', 
                submenu: false, 
                menu: 'enterMarks', 
                visible: true,
                disabled: false  
            },
            { 
                text: 'Reports', 
                icon: 'mdi-file-cabinet', 
                submenu: true, 
                menu: 'reports', 
                visible: true,
                disabled: false 
            },
            //{ text: 'Export Data', icon: 'mdi-database-export-outline', submenu: false, menu: 'exportData', visible: true },            
            //{ text: 'Import Students', icon: 'mdi-cloud-upload-outline', submenu: false, menu: 'importData',visible: false },
        ],       
        selectedItem: 0,      
    }),

    methods: {
        ...mapActions({
            registrationData: 'admin/downloadRegistrationData',
            getFormClasses: 'termReports/getFormClasses',
            getCodedCommentsList: 'termReports/getCodedComments',
            currentPeriod: 'termReports/getCurrentAcademicPeriod', 
        }),

        ...mapMutations({
            setMenuOptions: 'admin/setMenuOptions',
            editViewTermReportsOverlay: 'termReports/setEditViewTermReportsOverlay',
            editViewTermReportsLoading: 'termReports/setEditViewTermReportsLoading',
            formClasses: 'termReports/setFormClasses',
            setFormClassesList: 'termReports/setFormClassesList',
        }),

        async initialize () {
            let menuOptions = {};
            try {
                await this.currentPeriod();
            } catch (error) {
                if(error.response) console.log(error.response);
                else console.log(error);
            }
            
            this.items.forEach( value => {
                if(value.menu != 'dashboard')
                menuOptions[value.menu] = false;
                else menuOptions[value.menu] = true;
            })
            this.setMenuOptions(menuOptions);            
        },

        display(item){
            // console.log(`Selected: ${this.selectedItem}`);
            Object.keys(this.menuOptions).forEach(key => {
                this.menuOptions[key] = false;
            })
            this.menuOptions[item.menu] = true;
            this.setFormClasses();
            if(item.menu == 'termReports'){
                this.editViewTermReportsOverlay(true);
                this.editViewTermReportsLoading(true);                
            }
        },

        downloadExcel(){
            this.registrationData()
            .then(response => {                
                const url = window.URL.createObjectURL(new Blob([response.data]))
                const link = document.createElement('a')
                link.href = url
                link.setAttribute('download','Registration Data.xlsx')
                document.body.appendChild(link)                
                link.click()
                this.setExportData(false);                
            })
        },
        
        async setFormClasses(){
            let response = null;

            try {

                response = await this.getFormClasses();            
                this.setFormClassesList(response.data);
                let formClasses = [];
                let form1Classes = [];
                let form2Classes = [];
                let form3Classes = [];
                let form4Classes = [];
                let form5Classes = [];
                let form6Classes = [];            
                response.data.forEach(record => {
                    switch(record.form_level){
                        case 1:
                        form1Classes.push({
                            title: record.class_id,
                            formLevel: 1,
                        });
                        break;
                        case 2:
                        form2Classes.push({
                            title: record.class_id,
                            formLevel: 2,
                        });
                        break;
                        case 3:
                        form3Classes.push({
                            title: record.class_id,
                            formLevel: 3,
                        });
                        break;
                        case 4:
                        form4Classes.push({
                            title: record.class_id,
                            formLevel: 4,
                        });
                        break;
                        case 5:
                        form5Classes.push({
                            title: record.class_id,
                            formLevel: 5,
                        });
                        break;
                        case 6:
                        form6Classes.push({
                            title: record.class_id,
                            formLevel: 6,
                        });
                        break;
                    }                
                });
                formClasses.push({
                    title: 'Form 1',
                    action: 'mdi-school',                
                    items: form1Classes
                });
                formClasses.push({
                    title: 'Form 2',
                    action: 'mdi-school',                
                    items: form2Classes
                });
                formClasses.push({
                    title: 'Form 3',
                    action: 'mdi-school',                
                    items: form3Classes
                });
                formClasses.push({
                    title: 'Form 4',
                    action: 'mdi-school',                
                    items: form4Classes
                });
                formClasses.push({
                    title: 'Form 5',
                    action: 'mdi-school',                
                    items: form5Classes
                });
                formClasses.push({
                    title: 'Form 6',
                    action: 'mdi-school',                
                    items: form6Classes
                });            
                this.formClasses(formClasses);
                //console.log('main menu getting coded comments...');
                await this.getCodedCommentsList();
                
            } catch (error) {
                if(error.response) console.log(error.response);
                else console.log(error);
            }
            
            
        },
    }
}
</script>

<style scoped>
    ::v-deep .v-list-item__title{
        white-space: pre-wrap;
    }
</style>